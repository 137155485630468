body {
  background-color: #cae7f7;
  font-family: "Courier Prime";
  height: 100vh;
  overflow: hidden;
}

.main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.window-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.icons-container {
  width: fit-content;
  height: fit-content;
  margin: 2vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

.icon {
  margin: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon img {
  width: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.icon-text h4 {
  font-family: "Courier Prime", monospace;
  color: white;
  font-size: 16px;
  background-color: black;
  margin: 0px auto;
  padding: 4px;
}

.text-link {
  text-decoration: none;
}

input {
  background-color: black;
  border-style: none;
  font-family: inherit;
  font-size: 18px;
  color: white;
  caret-color: white;
}

input:focus {
  outline: none;
}

a {
  color: white;
  text-decoration: none;
}

.menu-item:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.menu-item:focus {
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
  border: none;
}

.componentLoader {
  color: white;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: c1 0.6s linear infinite alternate;
  transition: all 0.2s ease-in-out;
}
@keyframes c1 {
  to {
    opacity: 0;
  }
}

.pageLoader {
  color: black;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: c2 0.6s linear infinite alternate;
}
@keyframes c2 {
  to {
    opacity: 0;
  }
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.hideLoader {
  display: none;
}

.tag-wrapper p {
  min-width: fit-content;
  font-size: 15px;
  margin: 5px;
  padding: 2%;
  border-style: inset;
  border-width: thin;
}

.terminal-window {
  margin-top: 1%;
  height: 95vh;
  max-height: 95vh;
  min-height: 85vh;
  width: 80vw;
  max-width: 1800px;
  border-style: outset;
  background-color: black;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: all 0.2s ease-in-out;
}

.window-top-bar {
  background-color: #2d2b2b;
  width: 100%;
  height: 25px !important;
  display: flex;
  cursor: move;
}

.window-exit-button {
  width: 25px;
  height: 100%;
  border-right-style: ridge;
  border-width: 1px;
  border-color: rgb(159, 159, 159);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.window-exit-button img {
  height: 18px;
  width: 18px;
}

.window-top {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: not-allowed;
}

.window-top img {
  margin-top: 3vh;
  width: 60vw;
  height: 7vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.window-top h3 {
  color: white;
  font-size: 24px;
  margin-bottom: 0;
}

.terminal-content {
  color: white;
  margin: 20px 70px 30px;
  overflow: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}

.terminal-content h4 {
  font-size: 20px;
}

label {
  font-size: 20px;
}

li {
  color: white;
  font-size: 25px;
  list-style-type: "> ";
  margin-left: 50px;
  margin-bottom: 10px;
}

.terminal-content h3 {
  font-size: 23px;
  background-color: white;
  color: black;
  padding: 10px;
}

.about-paragraph {
  font-size: 18px;
  max-width: 60%;
  line-height: 1.5;
  text-align: justify;
  cursor: row-resize;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.about-container {
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  align-content: center;
  transition: all 0.2s ease-in-out;
}

.about-paragraph::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.headshot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headshot img {
  width: 50%;
  height: auto;
  aspect-ratio: 1/1;
}

.contact-container {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.contact-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.contact-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin: 5%;
}

.contact-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  margin: 35px;
}

.social-card {
  display: flex;
  flex-direction: column;
  height: 40%;
  font-size: 20px;
  padding-left: 10vw;
  padding-right: 10vw;
  margin: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.social-card a {
  background-color: black;
  padding: 5px;
}
.contact-card a {
  background-color: black;
  padding: 5px;
}

.social-card a:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px;
}

.social-card a:focus {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px;
  outline: none;
  border: none;
}

.contact-card a:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px;
}

.contact-card a:focus {
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 5px;
  outline: none;
  border: none;
}

.all-projects-container {
  cursor: row-resize;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.all-projects-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.projects-item {
  background-color: rgb(45, 45, 45);
  border-style: outset;
  height: fit-content;
  min-height: 300px;
  width: 25vw;
  min-width: 200px;
  max-width: 400px;
  margin: 5px;
  margin-top: 20px;
  padding-left: 2%;
  padding-right: 2%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.projects-item:hover {
  transform: scale(1.025);
}

.project-img {
  width: 100%;
  height: 200px;
  padding-top: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-img img {
  width: 95%;
}

.project-description {
  padding-top: 4%;
}

.project-description p {
  margin-left: 2%;
  font-size: 16px;
}

.project-description h4 {
  font-size: 24px;
  margin-top: 0;
  margin-left: 2%;
  margin-bottom: 0;
}

.tag-wrapper {
  margin: 0;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.tag-wrapper h5 {
  font-size: 14px;
  margin: 5px;
  padding: 2%;
  border-style: inset;
  border-width: thin;
  flex-wrap: nowrap;
  min-width: fit-content;
}

.project-detail {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}

.project-detail-container {
  padding-top: 2%;
  width: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease-in-out;
}

.project-column-1 {
  display: flex;
  width: 100%;
}

.project-header {
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-header h4 {
  margin-top: 0;
  font-size: 25px;
}

.project-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image-container img {
  width: 70%;
}

.project-detail-description {
  padding: 2%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.project-detail-description h5 {
  font-size: 18px;
  text-decoration: underline;
  margin: 0;
}

.project-detail-description p {
  font-size: 16px;
  line-height: 1.5;
}

@media screen and (max-width: 1124px) {
  body {
    height: 100dvh;
    overflow: hidden;
  }

  .main-container {
    height: 100dvh;
    overflow: hidden;
  }

  .window-container {
    position: absolute;
    width: 95%;
  }

  .mobile-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-start ul {
    width: 200px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mobile-start menu {
    padding-left: 0;
  }

  .mobile-start li {
    margin-top: 20px;
  }

  .headshot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1005;
    margin: 40px;
  }

  .headshot img {
    height: 200px;
    width: auto;
  }

  .terminal-window {
    height: 97vh;
    width: 95vw;
    border-style: outset;
    background-color: black;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  .window-top-bar {
    background-color: #2d2b2b;
    width: 100%;
    height: 30px;
    display: flex;
    cursor: move;
  }

  .window-exit-button {
    width: 30px;
    height: 30px;
    border-right-style: ridge;
    border-width: 1px;
    border-color: rgb(159, 159, 159);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
  }

  .window-exit-button img {
    height: 15px;
  }

  .window-top {
    background-color: black;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: not-allowed;
  }

  .window-top img {
    margin-top: 3vh;
    max-width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .window-top h3 {
    color: white;
    font-size: 3vw;
    margin-bottom: 0;
  }

  .about-paragraph {
    font-size: 18px;
    max-width: 100%;
    line-height: 1.2;
    padding: 10px;
  }

  .contact-item {
    font-size: 18px;
  }

  .social-card {
    flex-direction: column;
  }

  .social-card a {
    margin: 10px;
  }

  .project-description {
    padding: 5% 0%;
  }

  .project-description h4 {
    font-size: 20px;
  }

  .tag-wrapper {
    padding: 0% 0% 5%;
  }

  .project-detail-container {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
  }

  .project-column-1 {
    display: flex;
    width: 100%;
  }

  .projects-item {
    width: 32vw;
    min-height: 350px;
  }

  .projects-item:hover {
    transform: scale(1.025);
  }

  .project-img {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .terminal-window {
    position: absolute;
    height: 95%;
  }

  .terminal-content {
    margin: 5px 20px 7px;
  }

  .terminal-content h3 {
    font-size: 15px;
  }

  .window-top {
    margin-bottom: 5px;
  }

  .window-top img {
    width: 80vw;
  }

  .window-top h3 {
    font-size: 15px;
  }

  .componentLoader {
    font-size: 10px;
  }

  .pageLoader {
    font-size: 10px;
  }

  .projects-item {
    width: 60vw;
    min-width: 200px;
    min-height: auto;
    padding: 2%;
    height: fit-content;
  }

  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;
  }

  .about-container .headshot {
    display: none;
  }

  .about-paragraph {
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
  }
  .project-detail-container {
    padding: 0;
  }

  .project-detail-description {
    font-size: 16px;
    line-height: 1.2;
    text-align: left;
  }

  .project-image-container {
    padding: 0;
    padding-top: 3%;
  }

  .headshot {
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 40px;
  }
}

@font-face {
  font-family: "Courier Prime";
  src: url(./fonts/CourierPrime-Regular.ttf);
  font-display: swap;
}
